<template>
  <el-skeleton :loading="loading" class="order" animated>
    <template>
      <div class="contain" v-if="courseInfo">
        <div class="top flex p_lr_10">
          <div class="img-box">
            <el-image
                class="image"
                :src="imgToRespomse(courseInfo.coverUrl)"
            ></el-image>
          </div>
          <div class="flex1 flex flex_col m_l_08 p_lr_10">
            <div class="flex1">
              <div class="flex m_b_10">
                <div class="flex1 f35 bold txt_light_3 flex">{{ courseInfo.name }}</div>
              </div>
              <div>
                <div class="flex f25 txt_light_6 p_tb_04 align"><span>讲师：</span>{{ courseInfo.teacherName }}</div>
<!--                <div class="flex f25 txt_light_6 p_tb_04 align"><span>课程类型：</span>{{ courseInfo.type }}</div>-->
                <!-- <div class="flex f25 txt_light_6 p_tb_04 align"><span>已报名人数：</span>
                  {{ courseInfo.subNumber }}/{{ courseInfo.maximum }}
                </div> -->
                <div class="flex f25 txt_light_6 p_tb_04 align">
                  <span>开课时间：</span>
                  {{ courseInfo.courseStartTime }}
                  至
                  {{ courseInfo.courseEndTime ? courseInfo.courseEndTime.slice(-8) : '' }}
                </div>
                <!-- <div class="flex f25 txt_light_6 p_tb_04 align"><span>报名截止时间：</span>{{ courseInfo.endTime }}</div>
                <div class="flex txt_light_6 p_tb_04 align"
                     v-if="courseInfo.subscribeType == 1 && courseInfo.beginState == 2"
                     style="font-size: 1.4rem">
                  注：报名截止后，不能取消报名！
                  <span style="color: #048bc8;border-bottom: 1px solid #048bc8" @click="drump()">我的报名课程</span>
                </div> -->
              </div>
            </div>
            <!--          <div class="flex text-justify txt_light_6 f20 flex1 fontS">{{courseInfo.details}}</div>-->
            <!-- <div class="flex flex_end">
              <el-button class="bg_light txt_white f25"
                         v-if="courseInfo.subscribeType == 1 && courseInfo.beginState == 2"
                         round
                         @click="cancelSinup(courseInfo)">取消报名
              </el-button>
              <el-button class="bg txt_white f25"
                         style=""
                         v-if="courseInfo.subscribeType == 2 && courseInfo.beginState == 2"
                         round
                         @click="open(courseInfo)">立即报名
              </el-button>
              <el-button class="bg txt_white f25"
                         style=""
                         v-if="courseInfo.subscribeType == -1"
                         round
                         @click="open(courseInfo)">立即报名
              </el-button>
            </div> -->
          </div>
        </div>
        <!--      简介：-->
        <div class="course-body m_tb_10 bg_fff p_in_20 fs_16">
          <el-tabs value="first">
            <el-tab-pane label="课程介绍" name="first" style="cursor: auto;">
              <div class="course_details" v-html="courseInfo.details"></div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </template>
    <template slot="template">
      <div class="flex_row p_in_20" style="justify-content: space-between">
        <el-skeleton-item
            variant="image"
            style="width: 39%;height: 286px;"
        />
        <div class="p_in_20 flex_col" style="width: 60%">
          <el-skeleton-item class="m_tb_10" variant="h1" style="width: 50%;"/>
          <el-skeleton-item class="m_tb_10" variant="p" style="width: 20%;"/>
          <el-skeleton-item class="m_tb_10" variant="p" style="width: 25%;"/>
          <el-skeleton-item class="m_tb_10" variant="p" style="width: 32%;"/>
          <el-skeleton-item class="m_tb_10" variant="p" style="width: 25%;"/>
          <el-skeleton-item class="m_tb_10" variant="p" style="width: 30%;"/>
        </div>
      </div>
      <div class="p_in_20 flex_col">
        <el-skeleton-item class="m_tb_10" variant="h1" style="width: 80%;"/>
        <el-skeleton-item class="m_tb_10" variant="h1" style="width: 50%;"/>
        <el-skeleton-item class="m_tb_10" variant="h1" style="width: 20%;"/>
        <el-skeleton-item class="m_tb_10" variant="h1" style="width: 25%;"/>
        <el-skeleton-item class="m_tb_10" variant="h1" style="width: 32%;"/>
        <el-skeleton-item class="m_tb_10" variant="h1" style="width: 25%;"/>
        <el-skeleton-item class="m_tb_10" variant="h1" style="width: 30%;"/>
      </div>
    </template>
  </el-skeleton>
</template>

<script>
import shoucangSelect1 from '@/assets/img/avatar_01.gif'
import shoucang from '@/assets/img/shoucang.png'
import shoucangSelect from '@/assets/img/shoucang_select.png'
import {liveDetail_api, msubscribe_api, subscribeCancel_api} from '@/api/course/subscribe'
import {saveCollection_api, cancelCollection_api} from '@/api/mine'
import {mapGetters} from "vuex";
import {sessionGet} from "@/utils/local";
import defaultImg from '@/assets/image/default.png'
import {imgToRespomse} from '@/utils/imgToResponse'
export default {
  name: "order",
  data() {
    return {
      shoucangSelect1,
      shoucang,
      shoucangSelect,
      courseInfo: {},
      courseId: '',
      statusT: true,
      loading: true,
      defaultImg
    }
  },
  computed: {
    ...mapGetters({isLogin: "isLogin"}),
  },
  created() {
    this.userInfo = sessionGet('userInfo')
    this.courseId = this.$route.query.courseId
    this.getCourseInfo(this.courseId)
  },
  methods: {
    imgToRespomse(url){
      return imgToRespomse(url)
    },
    // 获取直播课程详情
    async getCourseInfo(courseId) {
      await liveDetail_api({id: courseId}).then(res => {
        if (res.code == 200) {
          this.courseInfo = res.data
          this.loading = false
        }
      })
    },
    getNow(s) {
      return s < 10 ? '0' + s : s;
    },
    getHu() {
      let d = new Date()
      let year = d.getFullYear()
      let month = d.getMonth() + 1
      let day = d.getDate()
      let h = d.getHours()
      let m = d.getMinutes()
      let ms = d.getSeconds()
      return year + '-' + this.getNow(month) + '-' + this.getNow(day) + ' ' + this.getNow(h) + ':' + this.getNow(m) + ':' + this.getNow(ms)
    },
    //提示框
    open(item) {
      if (!this.userInfo) {
        this.$message.warning('请先登录!')
        this.$router.push('/login')
        return
      }
      if (item.beTeacher == 1 && this.userInfo.role_name != 'teacher') {
        this.$message.warning('您不符合报名要求,报名只能是教师!')
        return
      }
      if (item.beTeacher == 2 && this.userInfo.role_name != 'student') {
        this.$message.warning('您不符合报名要求，报名只能是学生!')
        return
      }


      if (item.beType == 1) {
        if (item.deptId != this.userInfo.dept_id) {
          return this.$message.warning('校本课程，非本校学生不能参与课程报名!')
        }
      }
      if (this.userInfo.user_id == item.createUserId) {
        return this.$message.warning('不能报名自己创建的直播课!')
      }
      if (item.maximum == item.subNumber) {
        return this.$message.warning('直播课报名人数已满!')
      }

      this.$confirm('请确认是否预约课程《' + item.name + '》，在报名截止时间之后将不能退选。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.$message({
        //   type: 'success',
        //   message: '删除成功!'
        // });
        this.getMsubscribe(item)

      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
        return
      });
    },


    //直播课报名
    async getMsubscribe(item) {

      if (this.statusT == true) {
        this.statusT = false
        if (item.endTime > this.getHu()) {
          this.loading = true
          await msubscribe_api({id: item.id}).then(res => {
            this.statusT = true
            this.loading = false
            if (res.code == 200) {
              this.$message.success('报名成功')
              this.getCourseInfo(this.courseId)
            }
          })
        } else {
          this.statusT = true
          this.$message.warning('直播课报名截止，不能参与报名')
        }
      } else {
        this.$message.warning('请勿重复操作')

      }
    },
    //取消前面确认框
    cancelSinup(item) {
      this.$confirm('请确认是否取消报名-' + item.name + '-直播课,该直播课只能取消报名3次!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.getSubscribeCancel(item)
      }).catch(() => {
        return
      });
    },

    //直播课取消报名
    async getSubscribeCancel(item) {

      if (!this.userInfo) {
        this.$message.warning('请先登录!')
        return this.$router.push({path: '/login'})
      }

      if (this.statusT == true) {
        this.statusT = false
        if (item.endTime > this.getHu()) {
          this.loading = true
          await subscribeCancel_api({id: item.subscribeId}).then(res => {
            this.statusT = true
            this.loading = false
            if (res.code == 200) {
              this.$message.success(res.msg)
              this.getCourseInfo(this.courseId)
            } else {
              this.$message.warning(res.msg);
            }
          })
        } else {
          this.statusT = true
          this.$message.warning('超过报名截止时间，不能取消报名')
          return false
        }
      } else {
        this.$message.warning('请勿重复操作')
      }

    },
    // 点击收藏
    async collection(id) {
      if (!this.userInfo) {
        this.$message.warning('请先登录!')
        return this.$router.push({path: '/login'})
      }
      await saveCollection_api({resourcesId: id}).then(res => {
        if (res.code == 200) {
          this.getCourseInfo(this.courseId)
        }
      })
    },
    //取消收藏
    async cancelCollection(id) {
      await cancelCollection_api({id}).then(res => {
        if (res.code == 200) {
          this.getCourseInfo(this.courseId)
        }
      })
    },

    // 跳转到我的报名课程
    drump() {
      let userInfo = sessionGet('userInfo')
      if (userInfo.role_name == 'teacher') {
        this.$router.push('/mineTeacher/signUp')
      } else {
        this.$router.push('/mine/student/signUp')
      }

    },
  },

}
</script>

<style scoped>
.order {
  width            : 70%;
  margin           : 2em auto 0;
  background-color : #fff;
}

.flex {
  display : flex;
}

.flex_col {
  flex-direction : column
}

.flex_end {
  justify-content : flex-end;
}

.flex1 {
  flex : 1;
}

.f30 {
  font-size : 2em;
}

.f35 {
  font-size : 3em;
}

.f25 {
  font-size : 1.7rem
}

.f20 {
  font-size : 1em
}

.bg {
  background : #409eff;
}

.bg1 {
  background : #eeeeee;
}

.txt_white {
  color : #fff
}

.p_in_08 {
  padding    : 0.8em;
  box-sizing : border-box;
}

.p_in_05 {
  padding    : 0.5em;
  box-sizing : border-box;
}

.p_tb_08 {
  padding-top    : 0.8em;
  padding-bottom : 0.8em;
  box-sizing     : border-box;
}

.bold {
  font-weight : bold;
}

.txt_light_3 {
  color : #555;
}

.txt_light_6 {
  color : #888;
}

.text-justify {
  text-align : justify
}

.m_l_04 {
  margin-left : 1.4em;
}

.m_l_08 {
  margin-left : 1.8em;
}

.top {
  width      : 100%;
  margin     : 0 auto;
  padding    : 20px;
  box-sizing : border-box;
  min-height : 300px;
  background : #fff
}

.img-box {
  width  : 39%;
  height : 286px;
  border : 1px solid #eee;
}

.image {
  display    : block;
  width      : 100%;
  height     : 100%;
  object-fit : contain
}

.contain {
  width  : 95%;
  margin : 0 auto;
}

.txt_light {
  color : #666
}

.scang {
  display : flex;
  width   : 18px;
  height  : 18px;
}

.m_l_02 {
  margin-left : 0.2em
}

.bg_light {
  background : #f56c6c;
}

.fontS {
  width       : 69%;
  line-height : 2em;
  text-indent : 2em;
}

.algin {
  align-items : center
}

.bg_white {
  background : #fff;
  border     : 1px solid #eee
}

.p_tb_04 {
  padding    : 0.3em 0 0.3em 0;
  box-sizing : border-box;
}

.m_b_10 {
  margin-bottom : 2em
}

.p_lr_10 {
  padding-right : 3em;
  padding-left  : 2em;
  box-sizing    : border-box;
}

.align {
  align-items : center
}

/*::deep .el-button:focus, .el-button:hover{*/
/*  color: #fff;*/
/*  background: #edb060;*/
/*}*/

.course_details {
  line-height : 2.5rem;
}

::deep .course_details img {
  max-width : 100%;
  margin    : auto;
  display   : block;
}
</style>
